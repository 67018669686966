import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";//封面页
import Login from "../views/login.vue";//登录页
import layout from "../views/layout.vue";//菜单页面
import home from "../views/menu/home.vue";//菜单首页
import joinecosystem from "../views/menu/joinecosystem.vue";//菜单加入生态
import companyenter from "../views/menu/companyenter.vue";//菜单合作企业
import UserCenter from "../views/menu/UserCenter.vue";//个人资料
import userguide from "../views/menu/userguide.vue";//用户指南
import noticePage from "../views/menu/noticePage.vue";//用户指南
import digitalize from "../views/menu/digitalize.vue";//数字化解决方案
import IndustryInfo from "../views/menu/IndustryInfo.vue";//行业信息
import capacitymap from "../views/menu/capacitymap.vue";//产能地图
import databoard from "../views/menu/databoard.vue";//数据看板

import IndustryCollege from "../views/menu/IndustryCollege.vue";//数据看板
const routes: Array<RouteRecordRaw> = [
  {
    path: "/homeview",
    name: "HomeView",
    component: HomeView,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/UserCenter",
    name: "UserCenter",
    component: UserCenter,

  },
  {
    path: "/userguide",
    name: "userguide",
    component: userguide,

  },
  {
    path: "/notice",
    name: "notice",
    component: noticePage,

  },
  {
    path: "/",
    name: "layout",
    component: layout,
    children: [
      {
        path: "/home",
        name: "4",
        component: home,
      }, {
        path: "/joinecosystem",
        name: "3-3-2",
        component: joinecosystem,

      }, {
        path: "/companyenter",
        name: "3-3-1",
        component: companyenter,

      }, {

        path: "/digitalize",
        name: "3-2-1",
        component: digitalize,
      }, {
        path: "/IndustryInfo",
        name: "1-1-3",
        component: IndustryInfo,
      }, {
        path: "/capacitymap",
        name: "1-1-2",
        component: capacitymap,
      }, {

        path: "/databoard",
        name: "1-1-1",
        component: databoard,
      }, {
        path: "/industrycollege",
        name: "2-2",
        component: IndustryCollege,
      }
    ]
  },


];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
