import { createStore } from "vuex";
import appGlobal from "./modules/appglobal";
export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    /**
   * app全局参数
   */
    appGlobal
  },
});
