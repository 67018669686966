
  import { onMounted, ref, nextTick } from 'vue';
  import Player from 'xgplayer';
  import 'xgplayer/dist/index.min.css';
  //import 'xgplayer/dist/index.min.css';
  // import fullscreen from 'xgplayer/dist/controls/fullscreen';
  // import progress from 'xgplayer/dist/controls/progress';
  // import volume from 'xgplayer/dist/controls/volume';
  // import pip from 'xgplayer/dist/controls/pip';
  // import flex from 'xgplayer/dist/controls/flex';

  export default {
    name: 'videoxgbox',
    props: {
      videourl: {
        type: String,
        default: '',
      }
    },

    setup(props: any, ctx: any) {

      let player: any = null

      onMounted(() => {
        player = new Player({
          id: 'wxsxg',
          // el: document.querySelector('#wxsxg'),
          url: props.videourl,
          definitionActive: 'click',
          width: "100%",
          height: "100%",
          playbackRate: [0.5, 1, 1.5, 2], //传入倍速可选数组
          pip: true //打开画中画功能
        });
        player.emit('resourceReady', [{ name: '高清', url: 'url1' }, { name: '超清', url: 'url2' }]);
        player.play()
      })

      function seturl(url: any) {
        console.log(url)
        console.log(player)
        player.src = url
      }
      return {
        seturl,
        player
      }
    }
  }
