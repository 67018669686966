<template>
  <div class="pdf-viewer" ref="wrapRef" v-loading="loading" element-loading-text="Loading..."
    :element-loading-spinner="svg" element-loading-svg-view-box="-10, -10, 50, 50"
    element-loading-background="rgba(122, 122, 122, 0.8)">




    <div class="pdfcont" id="pdfCanvas">
      <iframe :src="pdfurl" frameborder="0" style="width: 100%; height: 100%; margin: auto; display: block"></iframe>
    </div>

    <div class="contalbox">


      <!-- <img @click="toScreenFull()" v-if="!isfull" class="ctrbtn" src="../../assets/images/IndustryCollege/9.png" alt=""
        srcset="">
      <img @click="toScreenFull()" width="18" class="ctrbtn" v-if="isfull"
        src="../../assets/images/capacitymap/tcqp.png" alt="" srcset=""> -->



    </div>
  </div>
</template>
<script>
  import { onMounted, ref, reactive, nextTick } from 'vue';

  // 导入自己的文件
  export default {
    name: 'PdfViewer',
    components: {

    },
    props: {

    },

    setup(props, ctx) {

      const isfull = ref(false)

      const pdfurl = ref('')
      const loading = ref(false)

      onMounted(() => {

      });



      function initPdf(url) {

        loading.value = true
        setTimeout(() => {
          loading.value = false
          pdfurl.value = url
        }, 1500)



      }



      function toScreenFull() {
        isfull.value = !isfull.value

        ctx.emit('screenbtn', true);
      }
      return {
        initPdf,
        toScreenFull,
        pdfurl,
        loading,
        isfull,
      };
    }

  };
</script>

<style lang="less" scoped>
  .pdf-viewer {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;

    .leftml {
      width: 180px;
      height: 100%;
      background: rgba(255, 255, 255, 0.06);
      overflow-y: auto;
      /* 如果缩略图过多，显示滚动条 */

      img {
        width: 100%;
        height: 100%;
      }
    }

    .pdfcont {
      flex: 1;

      .vue-pdf-embed {
        width: 100%;
        height: 100%;
      }
    }

    .contalbox {
      position: absolute;
      top: 2%;
      right: 12%;
      width: 36px;
      height: 36px;


      .kjb1 {
        width: 36px;
        background: #1a2533;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        cursor: pointer;
        margin-left: 60px;
        margin-bottom: 4px;
      }

      .ynno {
        width: 42px;
        height: 24px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        color: rgba(255, 255, 255, 0.25);
        margin-right: 4px;
      }
    }
  }
</style>