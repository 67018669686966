import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { useRouter } from "vue-router";
import { ElMessage } from 'element-plus'
const router = useRouter();
import { useStore } from "vuex";
const store = useStore();
// 创建一个自定义的axios实例
//Dev
// let apiurl = 'http://192.168.90.50:8080/jeecg-boot'



// //Test
// let apiurl = 'http://222.71.235.234:28080/createtogether'
//let apiurl = 'http://192.168.90.50/jeecgboot'

//Prod
//let apiurl = 'http://222.71.235.234:39880/createtogether'
let apiurl = 'https://yiqigongchuang.com/createtogether'


const imgurl = apiurl + '/sys/common/static'
const http: AxiosInstance = axios.create({
  baseURL: apiurl,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

// 请求拦截器
http.interceptors.request.use(
  (config) => {
    // 在发送请求之前做些什么，例如添加token等

    const adminJson = localStorage.getItem("token")
    // const adminJson = store.getters['token']

    console.log(adminJson)

    if (adminJson) {

      //设置请求头
      config.headers.togetherCreate = adminJson
    }

    return config;
  },
  (error: any) => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 响应拦截器
http.interceptors.response.use(
  (response: AxiosResponse) => {
    // 对响应数据做些什么，例如统一处理错误码等

    let res = response.data;
    console.log()
    //兼容的服务器返回的字符串数据
    if (typeof res === 'string') {
      res = res ? JSON.parse(res) : res
    }
    if (res.code === '401') {

      ElMessage.error('暂无权限请登录！')
      router.push('/login')
    }

    return response;
  },
  (error: any) => {
    // 对响应错误做些什么
    return Promise.reject(error);
  }
);

// 封装GET请求
export function get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
  return http.get<T>(url, config)
    .then(response => response.data);
}

// 封装POST请求
export function post<T>(url: string, data: any, config?: AxiosRequestConfig): Promise<T> {
  return http.post<T>(url, data, config)
    .then(response => response.data);
}


export const getFileAccessHttpUrl = (fileUrl: any, prefix = 'http') => {
  let result = fileUrl;
  try {
    if (fileUrl && fileUrl.length > 0 && !fileUrl.startsWith(prefix)) {
      //判断是否是数组格式
      let isArray = fileUrl.indexOf('[') != -1;
      if (!isArray) {
        let prefix = `${apiurl}/sys/common/static/`;
        // 判断是否已包含前缀
        if (!fileUrl.startsWith(prefix)) {
          result = `${prefix}${fileUrl}`;
        }
      }
    }
  } catch (err) { }
  return result;
};

export const getWebFileAccessHttpUrl = (fileUrl: any, prefix = 'http') => {
  let result = fileUrl;
  try {
    if (fileUrl && fileUrl.length > 0 && !fileUrl.startsWith(prefix)) {
      result = `${apiurl}${fileUrl}`;
    }
  } catch (err) { }
  return result;
};
export const uploadurl = () => {
  return apiurl + '/web/api/common/upload'
};









export const matchType = (fileName: any) => {
  // 后缀获取
  var suffix = '';
  // 获取类型结果
  var result: any = '';
  try {
    var flieArr = fileName.split('.');
    suffix = flieArr[flieArr.length - 1];
  } catch (err) {
    suffix = '';
  }
  // fileName无后缀返回 false
  if (!suffix) {
    result = false;
    return result;
  }
  // 图片格式
  var imglist = ['png', 'jpg', 'jpeg', 'bmp', 'gif'];
  // 进行图片匹配
  result = imglist.some(function (item) {
    return item == suffix;
  });
  if (result) {
    result = 'image';
    return result;
  };

  // 匹配 视频
  var videolist = ['mp4', 'm2v', 'mkv'];
  result = videolist.some(function (item) {
    return item == suffix;
  });
  if (result) {
    result = 'video';
    return result;
  };

  // 其他 文件类型
  result = 'other';
  return result;
};



export const arrGroup = (
  array: any[],
  subGroupLength: number,
): any[] => {
  let index: number = 0;
  const newArray: any[] = [];
  while (index < array.length) {
    newArray.push(array.slice(index, (index += subGroupLength)));
  }
  return newArray;
};





