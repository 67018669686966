/**
 * app全局变量
 *@author MuYi
 *@date 2022/3/21 8:58
 *@version 1.0
 */
export default {
  namespace: 'true',
  state() {
    return {
      /**
       * app信息
       */
      appInfo: {
        registerCompany: '请联系注册您的公司',
        version: '1.0.0',
        copyright: 'WinTown Software studio All rights reserved.',
        copyrightYear: '©2021-2022',
        author: '',
        ismyself: true
      },
      ismyself: false,
      userInfo: {
        name: ''

      },
      theme: {
        menuMode: 'vertical',
        colorBackground: '#009999',
      },
      token: '',
      showCustomerService: false,
      isDiaodig: false
    }
  },
  mutations: {
    /**
     * 设置app信息
     * @param appInfo
     */
    saveAppInfo(state: any, appInfo: any) {
      state.appInfo = appInfo;
    },
    saveTheme(state: any, theme: any) {
      state.theme = theme;
    },
    saveUserInfo(state: any, userInfo: any) {
      state.userInfo = userInfo;
    },
    saveToken(state: any, token: any) {
      state.token = token;
    },
    changemyself(state: any, ismyself: any) {
      state.ismyself = ismyself;
    },
    setShowCustomerService(state: any, showCustomerService: any) {
      state.showCustomerService = showCustomerService;
    },
    setShowDiaodig(state: any, isDiaodig: any) {
      state.isDiaodig = isDiaodig;
    },
  },
  actions: {
    updateTheme(context: any, theme: any) {
      context.commit("theme", theme);
    },
    setUserInfo(context: any, userInfo: any) {
      context.commit("userInfo", userInfo);
    },
    updateAppInfo(context: any, appInfo: any) {
      context.commit("appInfo", appInfo)
    },
    updateIsmyself(context: any, ismyself: any) {
      context.commit("ismyself", ismyself)
    },
  },
  getters: {
    theme(state: any) {
      return state.theme;
    },
    appInfo(state: any) {
      return state.appInfo;
    },
    userInfo(state: any) {
      return state.userInfo;
    },
    ismyself(state: any) {
      return state.ismyself;
    },
    token(state: any) {
      return state.token;
    },
    showCustomerService(state: any) {
      return state.showCustomerService;
    },
    showDiaodig(state: any) {
      return state.isDiaodig;
    },

  }
}
