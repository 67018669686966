import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import 'animate.css';
import "amfe-flexible/index.js";
// import "./api/rem.js";
// import 'lib-flexible-computer';
// import { setToken } from './api'; // 导入设置 token 的方法
import VueSplide from '@splidejs/vue-splide'
import ElementPlus from 'element-plus';
import zhCn from 'element-plus/dist/locale/zh-cn.mjs';
import 'element-plus/theme-chalk/index.css';
const app = createApp(App)
app.use(store).use(router).use(VueSplide).use(ElementPlus, {
  locale: zhCn,
}).mount("#app");
app.config.globalProperties.disbtn = true// 自定义添加
router.beforeEach((to, from, next) => {
  console.log(to)
  if (to.path === '/') {
    next('/homeview')
    return
  }
  next()
  // 从Session Storage获取数据
  // if (to.path === '/homeview') {
  //   next()
  //   return
  // }
  // if (to.path === '/home') {
  //   next()
  //   return
  // }
  // if (to.path === '/') {
  //   next('/home')
  //   return
  // }
  // if (to.path === '/login') {
  //   window.sessionStorage.removeItem("userData");
  //   next()
  //   return
  // }
  // const storedData = window.sessionStorage.getItem("userData");

  // if (storedData !== null) {
  //   const parsedData = JSON.parse(storedData);

  //   if (to.path === '/') {
  //     next({ path: '/home' })
  //     return
  //   } else {
  //     next()
  //   }

  //   return
  // } else if (storedData == null) {

  //   if (to.path === '/home') {
  //     next()
  //     return
  //   } else {
  //     next({ path: '/homeview' })
  //     return
  //   }

  // }




})
